exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-releases-index-js": () => import("./../../../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-artist-page-js": () => import("./../../../src/templates/artist-page.js" /* webpackChunkName: "component---src-templates-artist-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-marmalade-page-js": () => import("./../../../src/templates/marmalade-page.js" /* webpackChunkName: "component---src-templates-marmalade-page-js" */),
  "component---src-templates-music-page-js": () => import("./../../../src/templates/music-page.js" /* webpackChunkName: "component---src-templates-music-page-js" */),
  "component---src-templates-release-post-js": () => import("./../../../src/templates/release-post.js" /* webpackChunkName: "component---src-templates-release-post-js" */)
}

